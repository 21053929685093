import { ERPDocumentsTypesEnum } from "@/enums/document-type-enum";
import { emailRegex } from "@/utils/regex";
import { t } from "i18next";

const contactInputs1 = (state) => {
  return [
    {
      name: "firstName",
      label: "customers.modal.firstName",
      type: "text",
      placeholder: "customers.modal.firstName",
      required: true,
      parameterKey: "firstName",
      options: [],
      value: state?.firstName,
      isValid: true,
    },
    {
      name: "lastName",
      label: "customers.modal.lastName",
      type: "text",
      placeholder: "customers.modal.lastName",
      required: false,
      parameterKey: "lastName",
      options: [],
      value: state?.lastName,
      isValid: true,
    },
    {
      name: "title",
      label: "customers.modal.title",
      type: "text",
      placeholder: "customers.modal.title",
      required: false,
      parameterKey: "title",
      options: [],
      value: state?.title,
      isValid: true,
    },
    {
      name: "role",
      label: "customers.modal.role",
      type: "text",
      placeholder: "customers.modal.role",
      required: false,
      parameterKey: "position",
      options: [],
      value: state?.position,
      isValid: true,
    },
    {
      name: "address",
      label: "customers.modal.address",
      type: "text",
      placeholder: "customers.modal.address",
      required: false,
      parameterKey: "address",
      options: [],
      value: state?.address,
      isValid: true,
    },
    {
      name: "phone1",
      label: "customers.modal.phone1",
      type: "phone",
      placeholder: "customers.modal.phone1",
      required: false,
      parameterKey: "tel1",
      options: [],
      value: state?.tel1,
      isValid: true,
    },
    {
      name: "phone2",
      label: "customers.modal.phone2",
      type: "phone",
      placeholder: "customers.modal.phone2",
      required: false,
      parameterKey: "tel2",
      options: [],
      value: state?.tel2,
      isValid: true,
    },
    {
      name: "mobile",
      label: "customers.modal.mobile",
      type: "phone",
      placeholder: "customers.modal.mobile",
      required: false,
      parameterKey: "phone",
      options: [],
      value: state?.phone,
      isValid: true,
    },
    {
      name: "fax",
      label: "customers.modal.fax",
      type: "text",
      placeholder: "customers.modal.fax",
      required: false,
      parameterKey: "fax",
      options: [],
      value: state?.fax,
      isValid: true,
    },
    {
      name: "email",
      label: "customers.modal.email",
      type: "text",
      placeholder: "customers.modal.email",
      required: false,
      parameterKey: "mail",
      options: [],
      value: state?.mail,
      isValid: !!state?.mail,
      regex: emailRegex,
    },
    {
      name: "default in document",
      label: "customers.modal.defaultInDocument",
      type: "select",
      placeholder: "customers.modal.defaultInDocument",
      required: false,
      parameterKey: "defaultInDocsTypes",
      options: [
        { value: ERPDocumentsTypesEnum.quote, text: t("tabs.quotes") },
        { value: ERPDocumentsTypesEnum.order, text: t("tabs.orders") },
        { value: ERPDocumentsTypesEnum.deliveryNote, text: t("tabs.deliveryNotes") },
        { value: ERPDocumentsTypesEnum.invoice, text: t("tabs.invoices") },
        { value: ERPDocumentsTypesEnum.receipt, text: t("tabs.receipts") },
      ],
      value: state?.defaultInDocsTypes,
      multiple: true,
    },
  ];
};

export { contactInputs1 };

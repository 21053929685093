const PlusIcon = (props: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99935 4.16663V15.8333M4.16602 9.99996H15.8327"
        stroke="#272977"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const WalletIcon = (props: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.96031 14.0503V15.742C8.96031 17.1753 7.62697 18.3336 5.9853 18.3336C4.34363 18.3336 3.00195 17.1753 3.00195 15.742V14.0503C3.00195 15.4836 4.3353 16.5003 5.9853 16.5003C7.62697 16.5003 8.96031 15.4753 8.96031 14.0503Z"
        stroke={props?.stroke || "white"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.95883 11.7584C8.95883 12.175 8.84216 12.5584 8.64216 12.8917C8.15049 13.7 7.14215 14.2084 5.97548 14.2084C4.80882 14.2084 3.80047 13.6917 3.30881 12.8917C3.10881 12.5584 2.99219 12.175 2.99219 11.7584C2.99219 11.0417 3.3255 10.4 3.85884 9.93336C4.4005 9.45836 5.14214 9.17505 5.96714 9.17505C6.79214 9.17505 7.53383 9.46669 8.0755 9.93336C8.6255 10.3917 8.95883 11.0417 8.95883 11.7584Z"
        stroke={props?.stroke || "white"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.96031 11.7583V14.0499C8.96031 15.4833 7.62697 16.4999 5.9853 16.4999C4.34363 16.4999 3.00195 15.4749 3.00195 14.0499V11.7583C3.00195 10.3249 4.3353 9.16663 5.9853 9.16663C6.8103 9.16663 7.55199 9.45827 8.09366 9.92494C8.62699 10.3916 8.96031 11.0416 8.96031 11.7583Z"
        stroke={props?.stroke || "white"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3329 9.14159V10.8583C18.3329 11.3166 17.9662 11.6916 17.4995 11.7083H15.8662C14.9662 11.7083 14.1412 11.0499 14.0662 10.1499C14.0162 9.62494 14.2162 9.13327 14.5662 8.79161C14.8745 8.47494 15.2995 8.29163 15.7662 8.29163H17.4995C17.9662 8.30829 18.3329 8.68326 18.3329 9.14159Z"
        stroke={props?.stroke || "white"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.66602 8.74996V7.08329C1.66602 4.81663 3.03268 3.23329 5.15768 2.96662C5.37435 2.93329 5.59935 2.91663 5.83268 2.91663H13.3327C13.5493 2.91663 13.7577 2.92495 13.9577 2.95828C16.1077 3.20828 17.4993 4.79996 17.4993 7.08329V8.29164H15.766C15.2993 8.29164 14.8743 8.47495 14.566 8.79162C14.216 9.13328 14.016 9.62495 14.066 10.15C14.141 11.05 14.966 11.7083 15.866 11.7083H17.4993V12.9166C17.4993 15.4166 15.8327 17.0833 13.3327 17.0833H11.2493"
        stroke={props?.stroke || "white"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const ItemsCountIcon = (props: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4828 8.64998V11.3416C14.4828 13.5833 13.5828 14.4833 11.3411 14.4833H8.64948C6.40781 14.4833 5.50781 13.5833 5.50781 11.3416V8.64998C5.50781 6.40832 6.40781 5.5083 8.64948 5.5083H11.3411C13.5911 5.51663 14.4828 6.40832 14.4828 8.64998Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3324 4.80828V7.49996C18.3324 9.74163 17.4324 10.6416 15.1908 10.6416H14.4824V8.6583C14.4824 6.41664 13.5824 5.51662 11.3408 5.51662H9.35742V4.80828C9.35742 2.56662 10.2574 1.66663 12.4991 1.66663H15.1908C17.4324 1.66663 18.3324 2.56662 18.3324 4.80828Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.66602 15.1917V12.5001C1.66602 10.2584 2.56602 9.3584 4.80768 9.3584H5.51601V11.3417C5.51601 13.5834 6.41601 14.4834 8.65768 14.4834H10.641V15.1917C10.641 17.4334 9.74102 18.3334 7.49935 18.3334H4.80768C2.56602 18.3334 1.66602 17.4334 1.66602 15.1917Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export { PlusIcon, WalletIcon, ItemsCountIcon };

import { EAddressType } from "@/widgets/quote-new/business-widget/address-widget/state";

const addressInputs1 = (
  t,
  state: any,
  cities?: any[],
  filteredCityStreets?: any[]
) => {
  return [
    {
      name: "addressId",
      label: "customers.modal.addressId",
      type: "text",
      placeholder: "customers.modal.addressId",
      required: true,
      parameterKey: "address1",
      options: [],
      value: state?.address1,
      isValid: true,
    },
    {
      name: "addressType",
      label: "sales.cart.addressType",
      type: "select",
      placeholder: "sales.cart.addressType",
      required: false,
      parameterKey: "type",
      options: [
        {
          value: EAddressType.DELIVERY,
          text: `${t("sales.cart.deliveryAddress")}`,
        },
        {
          value: EAddressType.BILLING,
          text: `${t("sales.cart.billingAddress")}`,
        },
      ],
      value: state?.type,
    },
    {
      name: "city",
      label: "customers.modal.city",
      //type: "select",
      type: "text",
      placeholder: "customers.modal.city",
      required: false,
      parameterKey: "city",
      // options: cities.map((city) => ({
      //   value: city.Name,
      //   text: city.Name,
      // })),
      options: [],
      value: state?.city,
    },
    {
      name: "street",
      label: "customers.modal.street",
      // type: "select",
      type: "text",
      placeholder: "customers.modal.street",
      required: false,
      parameterKey: "street",
      // options: filteredCityStreets.map((street) => ({
      //   value: street.name,
      //   text: street.name,
      // })),
      options: [],
      value: state?.street,
      isValid: true,
    },
    {
      name: "home",
      label: "customers.modal.home",
      type: "text",
      placeholder: "customers.modal.home",
      required: false,
      parameterKey: "homeNumber",
      options: [],
      value: state?.homeNumber,
      isValid: true,
    },
    {
      name: "entrance",
      label: "customers.modal.entrance",
      type: "text",
      placeholder: "customers.modal.entrance",
      required: false,
      parameterKey: "entry",
      options: [],
      value: state?.entry,
      isValid: true,
    },
    {
      name: "floor",
      label: "customers.modal.floor",
      type: "text",
      placeholder: "customers.modal.floor",
      required: false,
      parameterKey: "floor",
      options: [],
      value: state?.floor,
      isValid: true,
    },
    {
      name: "apartment",
      label: "customers.modal.apartment",
      type: "text",
      placeholder: "customers.modal.apartment",
      required: false,
      parameterKey: "apartment",
      options: [],
      value: state?.apartment,
      isValid: true,
    },
    {
      name: "postalCode",
      label: "customers.modal.postalCode",
      type: "text",
      placeholder: "customers.modal.postalCode",
      required: false,
      parameterKey: "zipCode",
      options: [],
      value: state?.zipCode,
      isValid: true,
    },
    {
      name: "postbox",
      label: "customers.modal.po",
      type: "text",
      placeholder: "customers.modal.po",
      required: false,
      parameterKey: "postbox",
      options: [],
      value: state?.postbox,
      isValid: true,
    },
    {
      name: "country",
      label: "customers.modal.country",
      type: "text",
      placeholder: "customers.modal.country",
      required: false,
      parameterKey: "county",
      options: [],
      value: state?.county,
      isValid: true,
    },
    {
      name: "remarks",
      label: "customers.modal.remarks",
      type: "text",
      placeholder: "customers.modal.remarks",
      required: false,
      parameterKey: "notes",
      options: [],
      value: state?.notes,
      isValid: true,
    },
    {
      name: "default",
      label: "customers.modal.default",
      type: "switch",
      placeholder: "customers.modal.default",
      required: false,
      parameterKey: "isDefault",
      options: [],
      value: state?.isDefault,
      isValid: true,
    },
  ];
};

export { addressInputs1 };
